import { fromJS } from 'immutable';
import * as ActionTypes from '../lib/constants/ActionTypes';

import SessionRecord from '../records/session';

export const sessionsState = fromJS({
  loading: false,
  public_key: null,
  session: new SessionRecord(),
  modal: false,
  mode: 'redirect',
  pi4_confirmed: false
});

function setState(state, newState) {
  return state.merge(newState);
}

export default function sessions(state = sessionsState, action) {
  switch(action.type) {
    case ActionTypes.SESSION_LOADING:
      return setState(state, state.set('loading', action.payload));

    case ActionTypes.SET_PUBLIC_KEY:
      return setState(state, state.set('public_key', action.payload));

    case ActionTypes.SET_SESSION:
      const values = { ...{ id: action.payload.data.id }, ...action.payload.data.attributes };
      return setState(state, state.set('session', new SessionRecord(values)));

    case ActionTypes.SET_IS_MODAL:
      return setState(state, state.set('modal', action.payload));

    case ActionTypes.SET_MODE:
      return setState(state, state.set('mode', action.payload));

    case ActionTypes.PI4_CONFIRM:
      return setState(state, state.set('pi4_confirmed', true));

    default:
      return state;
  }
}
