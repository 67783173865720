import { Record } from 'immutable';
import { convertRawValues } from '../lib/utils/record_utils';

export default class AddressRecord extends Record({
  id: null,
  first_name: '',
  last_name: '',
  company_name: '',
  email: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  phone_number: '',
  validation_response: {}
}) {
  constructor(data) {
    data = convertRawValues(data);
    super(data);
  }

  get phone() {
    return this.phone_number;
  }

  get full_name() {
    return `${this.first_name || ''} ${this.last_name || ''}`.trim();
  }
}
