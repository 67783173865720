import * as ActionTypes from '../lib/constants/ActionTypes';

import SessionRecord from '../records/session';
import { getShoppingCart } from './shopping_carts';
import { getApplication } from './applications';
import { getCompany } from './companies';
import { getMerchant } from './merchants';
import { getUser } from './users';
import { getPrequalification } from './prequalifications';
import { setToken, savePublicKey, getPublicKey, removeCreditKey } from './authentication';
import { error } from './general';

const SESSION_DEPENDENCIES = {
  prequal: true,
  cart: true,
  application: true,
  user: true,
  company: true,
  merchant: true,
};

const validateRequest = (dispatch, state) => {
  let publicKey;

  if (state) publicKey = state().sessions.get('public_key');

  if (!getPublicKey() && !publicKey) {
    dispatch(error('No session is set - public_key: ' + publicKey));
    dispatch(loading(false));
  }
};

const path = (resource) => {
  if (!resource) resource = '';
  return { resource: 'api/sessions/' + resource };
};

const publicKeyFromState = state => state().sessions.get('public_key');
const publicKey = (key) => getPublicKey() ? getPublicKey() : key;

const loadDeps = (session, deps, dispatch) => {
  return Promise.all([
    deps.prequal &&
    session.loadDep('prequalified_user', dispatch, getPrequalification),
    deps.cart && session.loadDep('shopping_cart', dispatch, getShoppingCart),
    deps.application &&
    session.loadDep('application', dispatch, getApplication),
    deps.user && session.loadDep('user', dispatch, getUser),
    deps.company && session.loadDep('company', dispatch, getCompany),
    deps.merchant && session.loadDep('merchant', dispatch, getMerchant),
  ]);
};

export const loading = (bool) => ({
  type: ActionTypes.SESSION_LOADING,
  payload: bool,
});

export const setPublicKey = (params) => {
  let key;
  return (dispatch, getState) => {
    if (
      params.public_key &&
      params.public_key !== getState().sessions.get('public_key')
    ) {
      key = params.public_key;
      savePublicKey(key);
    } else if (getPublicKey()) {
      key = getPublicKey();
    }

    return (
      key &&
      dispatch({
        type: ActionTypes.SET_PUBLIC_KEY,
        payload: key,
      })
    );
  };
};

export const clearPublicKey = () => {
  return (dispatch, getState) => {
    removeCreditKey();

    return dispatch({
      type: ActionTypes.SET_PUBLIC_KEY,
      payload: null,
    });
  };
};

export const authenticate = (public_key) => {
  return (dispatch, getState, api) => {
    validateRequest(dispatch, getState);

    return api.post(path(publicKey(public_key) + '/authenticate'))
      .then((res) => {
        if (res.message && res.message === 'authorized') return true;
        res && dispatch(setToken(res));
      });
  }
};

export const getRoute = () => {
  return (dispatch, getState, api) => {
    validateRequest(dispatch, getState);
    const public_key = getState().sessions.get('public_key');

    return api.get(path(publicKey(public_key) + '/route'))
      .then((res) =>
        dispatch({
          type: ActionTypes.SET_SESSION,
          payload: res,
        })
    );
  };
};

export const afterAuthRoute = (history, twofactor) => {
  return (dispatch, getState) => {
    const session = getState().sessions.get('session');

    if (
      twofactor ||
      (!session.workflow && !session.action) ||
      session.hasCustomFlag('recharge') ||
      session.hasCustomFlag('instore')
    ) {
      return dispatch(getRoute());
    } else {
      return history.push('/' + session.workflow + '/' + session.action);
    }
  };
};

export const shouldGetInitRoute = (session) => {
  return !session.workflow || session.workflow === '';
};

export const init = () => {
  return (dispatch, getState, api) => {
    dispatch(loading(true));
    const publicKey = getState().sessions.get('public_key');

    if (!publicKey && !getPublicKey()) {
      return Promise.reject(dispatch(loading(false)));
    }

    return dispatch(authenticate(publicKey))
      .then((res) => dispatch(getSession()))
      .then((res) => {
        if (shouldGetInitRoute(getState().sessions.get('session'))) {
          return dispatch(getRoute());
        }
        return Promise.resolve(false);
      })
      .then((res) => dispatch(loading(false)));
  };
};

export const createSession = (merchantKey, prequalificationId, subDomain = []) => {
  return (dispatch, getState, api) => {
    // also needs to add public_key to session reducer
    return api
      .post(path(), {
        slug: merchantKey,
        prequalification_id: prequalificationId,
        mode: getState().sessions.get('modal') ? 'modal' : getState().sessions.get('mode'),
        custom_flags: subDomain
      })
      .then((res) => {
        dispatch(setPublicKey(res.data.attributes));
        res.data.attributes.token &&
          dispatch(setToken(res.data.attributes.token));

        return dispatch({
          type: ActionTypes.SET_SESSION,
          payload: res,
        });
      })
      .then((res) => {
        let session = getState().sessions.get('session');
        return loadDeps(session, SESSION_DEPENDENCIES, dispatch);
      });
  };
};

export const getSession = (deps = {}) => {
  return (dispatch, getState, api) => {
    validateRequest(dispatch, getState);

    return api
      .get(
        path(
          deps.referring
            ? getState().sessions.get('session').depId('referring_session')
            : publicKey(publicKeyFromState(getState))
        )
      )
      .then((res) => {
        if (deps.referring) {
          return res;
        } else {
          return dispatch({
            type: ActionTypes.SET_SESSION,
            payload: res,
          });
        }
      })
      .then((res) => {
        if (deps.referring) return res;

        let session = getState().sessions.get('session');
        return loadDeps(
          session,
          { ...SESSION_DEPENDENCIES, ...deps },
          dispatch
        );
      });
  };
};

export const updateSession = (session, deps = {}) => {
  return (dispatch, getState, api) => {
    validateRequest(dispatch, getState);

    return api
      .edit(path(publicKey(publicKeyFromState(getState))), session)
      .then((res) => {
        res.data.attributes.token &&
          dispatch(setToken(res.data.attributes.token));
        return res;
      })
      .then((res) =>
        dispatch({
          type: ActionTypes.SET_SESSION,
          payload: res,
        })
      )
      .then((res) => {
        let session = getState().sessions.get('session');
        return loadDeps(
          session,
          { ...SESSION_DEPENDENCIES, ...deps },
          dispatch
        );
      });
  };
};

export const reestablishSession = (user, token, deps = {}) => {
  return (dispatch, getState, api) => {
    return api
      .post(path(publicKey(publicKeyFromState(getState)) + '/reestablish'), { user_id: user.id })
      .then((res) =>
        dispatch({
          type: ActionTypes.SET_SESSION,
          payload: res,
        })
      )
      .then((res) => {
        let session = getState().sessions.get('session');
        savePublicKey(session.public_key);
        dispatch(setToken(token));
        return session;
      })
      .then((res) =>
        loadDeps(res, { ...SESSION_DEPENDENCIES, ...deps }, dispatch)
      );
  };
};

export const finishSession = (type) => {
  if (!type) return Promise.reject('Missing type');

  return (dispatch, getState, api) => {
    validateRequest(dispatch, getState);

    const key = publicKey(publicKeyFromState(getState));

    return key ? api.edit(path(key + '/finish'), { type: type }) : Promise.reject('missing public key');
  };
};

export const notifyOwner = (owner, purchaser) => {
  return (dispatch, getState, api) => {
    validateRequest(dispatch, getState);

    return api.post(
      path(publicKey(publicKeyFromState(getState)) + '/notify_owner'),
      { owner, purchaser },
    );
  };
};

export const getReferringSession = () => {
  return (dispatch, getState) => {
    validateRequest(dispatch);

    return dispatch(
      getSession({
        merchant: false,
        prequal: false,
        user: false,
        application: false,
        cart: false,
        company: false,
        referring: true,
      })
    ).then((res) => new SessionRecord(res.data.attributes));
  };
};

export const resubmitApplication = (session) => {
  return (dispatch, getState, api) => {
    validateRequest(dispatch, getState);

    return api
      .edit(path(publicKey(publicKeyFromState(getState)) + '/resubmit'), session)
      .then((res) =>
        dispatch({
          type: ActionTypes.SET_SESSION,
          payload: res,
        })
      )
      .then((res) => {
        let session = getState().sessions.get('session');
        return loadDeps(session, SESSION_DEPENDENCIES, dispatch);
      });
  };
};

export const verifyOwnerPin = (pin) => {
  return (dispatch, getState, api) => {
    validateRequest(dispatch, getState);

    return api.post(path(publicKey(publicKeyFromState(getState)) + '/verify_owner_pin'), { pin: pin });
  };
};

export const existingUserEvent = (heap, user) => {
  return (dispatch, getState) => {
    const session = getState().sessions.get('session');

    const merchant = getState()
      .entities.get('merchant')
      .get(session.depId('merchant'));

    const cart = getState()
      .entities.get('shopping_cart')
      .get(session.depId('shopping_cart'));

    return heap.track({
      merchant,
      session,
      user,
      payload: { amount: cart ? cart.grand_total : 0 },
    });
  };
};

export const existingUserEventFS = (fs, user) => {
  return (dispatch, getState) => {
    const session = getState().sessions.get('session');

    const merchant = getState()
      .entities.get('merchant')
      .get(session.depId('merchant'));

    const shopping_cart = getState()
      .entities.get('merchant')
      .get(session.depId('shopping_cart'));

    fs.identify(user.email, { displayName: user.name });

    fs.event({
      merchant,
      session,
      user,
      shopping_cart,
    });
  };
};

export const existingUserEventGA = (ga, user) => {
  return (dispatch, getState) => {
    const session = getState().sessions.get('session');
    const merchant = getState()
      .entities.get('merchant')
      .get(session.depId('merchant'));

    ga.identify({
      'id': user.id,
      'sessionId': session.id,
      'sessionKey': session.session_key,
      'merchantId': merchant.id,
      'publicKey': session.public_key,
    });
  };
};

export const setIsModal = bool => ({
  type: ActionTypes.SET_IS_MODAL,
  payload: bool
});

export const setMode = value => ({
  type: ActionTypes.SET_MODE,
  payload: value
});

export const pi4Confirm = () => ({
  type: ActionTypes.PI4_CONFIRM
});
