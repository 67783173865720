import React from 'react';

import { ModalWrapper } from '.';
import OutlineButton from '../buttons/OutlineButton';
import PrimaryButton from '../buttons/PrimaryButton';

export default function AddressValidationModal({
  address,
  validatedAddress,
  confirmAddress,
  handleSubmit,
  copyAddressToModel,
  isLoading,
  isModalActive,
  toggleModal,
}) {
  if (!address) return null;
  if (!validatedAddress) return null;

  const boldIfDiff = field => {
    if (validatedAddress.diff.includes(field)) {
      return <span className="font-semibold">{validatedAddress[field]}</span>;
    }

    return validatedAddress[field];
  };

  return (
    <ModalWrapper isModalActive={isModalActive} toggleModal={toggleModal}>
      <div className="px-2 sm:p-3" role={AddressValidationModal.role}>
        <header className="text-lg sm:text-2xl font-semibold text-primary-blue mb-4">
          Address Validation
        </header>
        <section>
          <div className="flex flex-col gap-4 mb-2 modal:mb-0">
            <p className="font-light text-sm sm:text-base">
              Please Verify
            </p>
            <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-6 text-sm">
              <div className="flex flex-col gap-1 sm:gap-2 sm:w-1/2">
                <p className="font-semibold text-secondary-gray">
                  You entered:
                </p>
                <div>
                  <p>
                    {address.address1} {address.address2}
                    <span className="inline sm:block">
                      {address.city}, {address.state}{' '}
                      {address.zip}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-1 sm:gap-2 sm:w-1/2">
                <p className="font-semibold text-secondary-gray">
                  Recommended Address:
                </p>
                <div>
                  <p>
                    {boldIfDiff('address1')} {validatedAddress.address2}
                    <span className="inline sm:block">
                      {boldIfDiff('city')}, {boldIfDiff('state')}{' '}
                      {boldIfDiff('zip')}{' '}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <p className="font-light text-secondary-gray text-xs sm:text-sm tracking-wide">
              *Note that inaccurate information may result in a declined
              application if we are unable to verify your address.
            </p>
          </div>
          <div className="sm:flex sm:justify-between sm:gap-4">
            <OutlineButton
              buttonText="Use Entered Address"
              buttonColor="border-primary-blue text-primary-blue hover:text-primary-blue-hover hover:border-primary-blue-hover"
              isSmall={true}
              onClick={() => {
                confirmAddress();
                handleSubmit();
                toggleModal();
              }}
            />
            <PrimaryButton
              buttonText="Use Suggested Address"
              buttonColor="bg-primary-blue"
              hoverClass="hover:bg-primary-blue-hover"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={() => {
                copyAddressToModel(validatedAddress);
                confirmAddress();
                handleSubmit();
                toggleModal();
              }}
            />
          </div>
        </section>
      </div>
    </ModalWrapper>
  );
}

AddressValidationModal.role = 'address-validation-modal-component';

