// ----------------------
// ENTITIES
// ----------------------
export const CLEAR_ENTITIES = 'CLEAR_ENTITIES';
export const CLEAR_ENTITY = 'CLEAR_ENTITY';
export const RECEIVE_ENTITIES = 'RECEIVE_ENTITIES';
export const RECEIVE_ENTITY = 'RECEIVE_ENTITY';

// ----------------------
// SESSIONS
// ----------------------
export const SESSION_LOADING = 'SESSION_LOADING';
export const SET_SESSION = 'SET_SESSION';
export const SET_PUBLIC_KEY = 'SET_PUBLIC_KEY';
export const SET_IS_MODAL = 'SET_IS_MODAL';
export const SET_MODE = 'SET_MODE';
export const PI4_CONFIRM = 'PI4_CONFIRM';

// ----------------------
// AUTHENTICATION
// ----------------------
export const TOKEN_LOADING = 'TOKEN_LOADING';
export const SET_TOKEN = 'SET_TOKEN';

// ----------------------
// GENERAL
// ----------------------
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_DEBUG = 'SET_DEBUG';
export const SET_ERROR = 'SET_ERROR';
export const TOGGLE_RETHROW = 'TOGGLE_RETHROW';

// ----------------------
// FORMS
// ----------------------
export const UPDATE_INPUT = 'UPDATE_INPUT';
