import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../Logo';
import ReturnToMerchant from '../ReturnToMerchant';

export default function FullHeader({ dismiss, merchant, headerElements }) {
  return (
    <nav className="h-12 sm:h-16 py-4 sm:py-0 px-2 md:px-16 shadow-none flex justify-between items-center rounded-none border-solid border-b-[3px] border-border-gray" role="full-header-component">
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center">
          {headerElements.close_icon ? (
            <a
              id="cancel"
              role="close-icon"
              onClick={dismiss}
              className="text-xs md:text-base cursor-pointer text-primary-blue hover:text-primary-blue-hover mx-4 md:mx-0 md:mr-4"
            >
              <FontAwesomeIcon icon="times" />
            </a>
          ) : (
            <div className="w-4"></div>
          )}

          {headerElements.logo ? <Logo merchant={merchant} /> : null}
        </div>

        <div>
          <div className="flex items-center mr-4 md:mx-0 gap-2 sm:gap-4 md:gap-6">
            {headerElements.merchant_link ? (
              <ReturnToMerchant merchant={merchant} />
            ) : null}

            <a
              id="learn-more"
              href={merchant?.learn_more_link}
              target="_new"
              className="flex items-center gap-2 text-sm text-primary-blue"
            >
              <FontAwesomeIcon icon={['far', 'question-circle']} />
              <p className="hidden md:inline">Learn More</p>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

FullHeader.role = 'full-header-component';
