import React from 'react';
import FullHeader from './FullHeader';
import LogoHeader from './LogoHeader';

export default function StaticHeader({
  isLoading,
  merchant,
  session,
  dismiss,
}) {
  const disableHeaderOverride =
    merchant?.integration_configuration?.get('disable_header') === true;
  const showCloseNavigationOverride =
    merchant?.integration_configuration?.get('show_close_navigation') !== false;

  // we're shown in a Merchant's app / webview / whatever
  const logoOnlyOverride = session.mode === 'mobile';
  const headerElements = {
    close_icon: showCloseNavigationOverride,
    logo: true,
    merchant_link: false,
  };

  if (session?.session_type === 'apply_now' && session?.mode !== 'modal') {
    headerElements.merchant_link = true;
  }

  if (disableHeaderOverride) return null;

  if (logoOnlyOverride || isLoading) return <LogoHeader merchant={merchant} />;

  return (
    <FullHeader
      dismiss={dismiss}
      merchant={merchant}
      headerElements={headerElements}
    />
  );
}
