import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ApplicationRecord from '../../../records/application';
import ColumnInput from '../../shared/inputs/ColumnInput';
import Loading from '../../shared/Loading';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import ProgressBars from '../../shared/ProgressBars';
import StickyInput from '../../shared/inputs/StickyInput';
import StickySelect from '../../shared/inputs/StickySelect';
import Tooltip from '../../shared/Tooltip';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSessionEntity from '../../../hooks/useSessionEntity';
import useValidateRoute from '../../../hooks/useValidateRoute';
import { formatEstablishedDate } from '../../../lib/utils/formatters';
import { asyncActions, handleAsyncError } from '../../../actions/general';
import { getRoute, loading, updateSession } from '../../../actions/sessions';
import {
  states,
  poBoxRegex,
  subDomains,
  emojiRegexTest,
  googleAddressValidationApiKey,
} from '../../../lib/constants/SiteVariables';
import { validateEin } from '../../../actions/companies';
import { validateEstablishedDate } from '../../../lib/utils/validations';
import { validatePhone } from '../../../actions/users';
import { usePlacesWidget } from 'react-google-autocomplete';
import { addressFromPlace, validateAddress } from '../../../lib/utils/address_validation';
import AddressUnconfirmedModal from '../../shared/modal/AddressUnconfirmedModal';
import useModal from '../../../hooks/useModal';
import AddressValidationModal from '../../shared/modal/AddressValidationModal';

export default function NewBusinessDetails({ location }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const merchant = useSessionEntity('merchant');
  const application = useSessionEntity('application');
  const session = useSelector((state) => state.sessions.get('session'));
  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const asyncErrors = useSelector((state) => state.general.get('errors'));
  const [foundUser, setFoundUser] = useState(false);
  const [newApplication, setNewApplication] = useState(
    application?.set(
      'date_established',
      formatEstablishedDate(application?.get('date_established')),
    ),
  );
  const [addressConfirmed, setAddressConfirmed] = useState(false);
  const [validatedAddress, setValidatedAddress] = useState(null);
  const [previousResponseId, setPreviousResponseId] = useState(null);
  const confirmModal = useModal();
  const validationModal = useModal();
  const addressAutoPop = usePlacesWidget({
    apiKey: googleAddressValidationApiKey,
    onPlaceSelected: (place) => {
      if (!place.address_components) return;

      copyAddressToModel(addressFromPlace(place));
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" },
    },
  });
  const formRef = useRef();

  const copyAddressToModel = address => {
    const values = {...formRef.current.values}
    values.business_address.address1 = address.address1;
    values.business_address.address2 = address.address2;
    values.business_address.city = address.city;
    values.business_address.state = address.state;
    values.business_address.zip = address.zip;
    formRef.current.setValues(values)
  };

  useRouteStatus(location);

  useEffect(() => {
    if (foundUser) history.push('/init/existing_user');
  }, [foundUser]);

  useEffect(() => {
    if (
      asyncErrors.count() > 0 &&
      asyncErrors.find((e) => e?.includes('User already registered'))
    ) {
      setFoundUser(true);
      dispatch(loading(false));
    }
  }, [asyncErrors]);

  useEffect(() => {
    setNewApplication(application);
    dispatch(loading(false));
  }, [application]);

  const checkEin = async (val) => {
    try {
      const res = await dispatch(validateEin(val));
      return res.result;
    } catch (e) {
      dispatch(handleAsyncError(e));
    }
  };

  const checkPhone = async (val) => {
    try {
      const res = await dispatch(validatePhone(val));
      return res.result;
    } catch (e) {
      dispatch(handleAsyncError(e));
    }
  };

  const save = (values) => {
    dispatch(loading(true));

    return dispatch(
      asyncActions(
        updateSession(
          {
            ...session.setCustomFlag(subDomains.subDomain()).toJS(),
            application: new ApplicationRecord(values).toJS(),
          },
          { cart: false, prequal: false, merchant: false },
        ),
        getRoute(),
      ),
    ).then((res) => {
      dispatch(loading(false));
    });
  };

  if (!useValidateRoute({ apply: true, company: true }))
    return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

  if (!newApplication || !merchant) {
    return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;
  }

  if (newApplication && merchant) {
    return (
      <div id="content" role="main" className="mx-6 sm:mx-auto">
        <ProgressBars />
        <div className="mt-4 mb-1 sm:mb-4">
          <div className="text-base sm:text-xl sm:text-4xl font-extrabold sm:mb-2">
            Business Information
          </div>
        </div>
        <div className="tracking-wide mb-4 sm:mb-6 font-light text-sm sm:text-lg">
          Share with us some additional details about your business to see if
          you qualify.
        </div>

        <Formik
          initialValues={{
            ...newApplication.toJS(),
          }}
          innerRef={formRef}
          validate={async (values) => {
            const errors = {};
            const addNested = (field, msg) =>
              (errors['business_address'] = {
                ...errors['business_address'],
                [field]: msg,
              });

            Object.keys(values).forEach((key) => {
              const value = values[key];

              if (typeof value === 'string' && emojiRegexTest.test(value)) {
                errors[key] = '* Emojis are not accepted.';
              }
            });

            if (
              !values.business_address.address1 ||
              values.business_address.address1.trim().length < 2
            ) {
              addNested('address1', '* Required');
            }

            if (poBoxRegex.test(values.business_address?.address1?.trim())) {
              addNested('address1', '* P.O. Boxes are not accepted.');
            } else if (
              poBoxRegex.test(values.business_address?.address2?.trim())
            ) {
              addNested('address2', '* P.O. Boxes are not accepted.');
            }

            if (emojiRegexTest.test(values.business_address?.address1?.trim())) {
              addNested('address1', '* Emojis are not accepted.');
            }

            if (emojiRegexTest.test(values.business_address?.address2?.trim())) {
              addNested('address2', '* Emojis are not accepted.');
            }

            if (
              !values.business_address.city ||
              values.business_address.city.trim().length < 2
            ) {
              addNested('city', '* Required');
            }

            if (emojiRegexTest.test(values.business_address?.city?.trim())) {
              addNested('city', '* Emojis are not accepted.');
            }

            if (!values.business_address.state)
              addNested('state', '* Required');
            if (!values.business_address.zip) {
              addNested('zip', '* Required');
            } else if (values.business_address.zip.trim().length < 5) {
              addNested('zip', '* Must be 5 digits');
            }

            if (!values.business_phone) {
              errors.business_phone = '* Required';
            } else if (
              values.business_phone.replace(/[^\d]/g, '').length < 10
            ) {
              errors.business_phone = '* Please enter a valid phone number';
            }

            if (!values.ein) {
              errors.ein = '* Required';
            } else if (values.ein.trim().length < 10) {
              errors.ein = '* Must be 9 characters in length';
            }

            if (!values.date_established) {
              errors.date_established = '* Required';
            } else if (
              values.date_established.replace(/[^\d]/g, '').length < 6
            ) {
              errors.date_established = '* Please enter a valid date';
            }

            return errors;
          }}
          onSubmit={async (values, { setFieldError }) => {
            dispatch(loading(true));
            const validated = validatedAddress ? validatedAddress :
              await validateAddress(values.business_address, null, previousResponseId);
            setValidatedAddress(validated);
            values.business_address.validation_response = JSON.stringify(validated.response);

            if (!previousResponseId) {
              setPreviousResponseId(validated.response.responseId);
            }

            const unconfirmed = validated.address.unconfirmedComponents.length;
            const suggestedChange = validated.address.diff.length;

            if (unconfirmed && !addressConfirmed) {
              confirmModal.toggleModal();
            }

            if (!unconfirmed && suggestedChange && !addressConfirmed) {
              validationModal.toggleModal();
            }

            const einCheck = await checkEin(values.ein);
            const phoneCheck = await checkPhone(values.business_phone);

            const establishedCheck = validateEstablishedDate(
              values.date_established,
            );

            if (!establishedCheck) {
              setFieldError('date_established', `* Please enter a valid date`);
            }
            if (!phoneCheck.valid) {
              setFieldError(
                'business_phone',
                '* Please enter a valid phone number',
              );
            }
            if (!einCheck.valid) {
              setFieldError('ein', einCheck.error);
            }
            einCheck.valid && phoneCheck.valid && establishedCheck &&
              ((!unconfirmed && !suggestedChange) || addressConfirmed)
              ? save(values)
              : dispatch(loading(false));
          }}
        >
          {({ errors, setFieldValue, touched, values, submitForm }) => (
            <Form role="form" className="mx-auto">
              <ColumnInput
                error={errors?.business_address?.address1}
                touched={touched?.business_address?.address1}
              >
                <StickyInput
                  inputRef={addressAutoPop?.ref}
                  id="business_address.address1"
                  placeholder="Business Street Address"
                  values={values}
                  onChange={(e) => {
                    setFieldValue("business_address.address1", e.target.value);
                    setValidatedAddress(null);
                  }}
                />
              </ColumnInput>

              <ColumnInput
                error={errors?.business_address?.address2}
                touched={touched?.business_address?.address2}
              >
                <StickyInput
                  id="business_address.address2"
                  placeholder="Suite or Floor"
                  values={values}
                />
              </ColumnInput>

              <ColumnInput
                error={{
                  city: errors?.business_address?.city,
                  state: errors?.business_address?.state,
                  zip: errors?.business_address?.zip,
                }}
                touched={{
                  city: touched?.business_address?.city,
                  state: touched?.business_address?.state,
                  zip: touched?.business_address?.zip,
                }}
                className="flex flex-col mobile:flex-row justify-between gap-y-2 sm:gap-y-0 mobile:gap-x-1"
              >
                <StickyInput
                  id="business_address.city"
                  className="mobile:w-3/5 w-full"
                  placeholder="City"
                  values={values}
                  onChange={(e) => {
                    setFieldValue("business_address.city", e.target.value);
                    setValidatedAddress(null);
                  }}
                />
                <StickySelect
                  id="business_address.state"
                  className="mobile:w-1/5 w-full"
                  placeholder="State"
                  options={states}
                  shortOptionsDisplay={true}
                  values={values}
                  onChange={(e) => {
                    setFieldValue("business_address.state", e.target.value);
                    setValidatedAddress(null);
                  }}
                />
                <StickyInput
                  id="business_address.zip"
                  className="mobile:w-1/5 w-full"
                  mask="99999"
                  placeholder="Zip"
                  values={values}
                  onChange={(e) => {
                    setFieldValue("business_address.zip", e.target.value);
                    setValidatedAddress(null);
                  }}
                />
              </ColumnInput>

              <ColumnInput
                error={errors.business_phone}
                touched={touched.business_phone}
              >
                <StickyInput
                  id="business_phone"
                  type="tel"
                  mask="(999) 999-9999"
                  placeholder="Business Phone Number"
                  values={values}
                />
              </ColumnInput>

              <ColumnInput error={errors.ein} touched={touched.ein}>
                <StickyInput
                  inputMode="numeric"
                  type="tel"
                  id="ein"
                  mask="99-9999999"
                  placeholder="EIN"
                  values={values}
                  disclaimer={
                    newApplication?.business_type === 'Sole Proprietorship' ? (
                      <Tooltip
                        tooltipText={
                          <p>
                            <span className="font-bold mr-1">
                              Don't have an EIN?
                            </span>
                            As a sole proprietor, you may enter your social
                            security number (SSN) to identify your business
                            instead of your employer identification number
                            (EIN).
                          </p>
                        }
                        triggerClass="px-1 mt-1 text-xs sm:text-sm hover:cursor-pointer hover:text-primary-blue text-left"
                        containerClass="rounded-lg w-72 p-3 bg-border-gray text-xs"
                        placement="bottom-start"
                      >
                        <FontAwesomeIcon
                          icon={['fal', 'circle-question']}
                          className="mr-1"
                        />
                      </Tooltip>
                    ) : undefined
                  }
                />
              </ColumnInput>
              <ColumnInput
                error={errors.date_established}
                touched={touched.date_established}
              >
                <StickyInput
                  id="date_established"
                  type="tel"
                  placeholder="Date Established (MM/YYYY)"
                  inputMode="numeric"
                  mask="99/9999"
                  values={values}
                />
              </ColumnInput>

              <PrimaryButton
                buttonText="Next"
                buttonColor="bg-primary-blue"
                hoverClass="hover:bg-primary-blue-hover"
                disabled={isLoading}
                isLoading={isLoading}
              />
              <AddressUnconfirmedModal
                address={validatedAddress?.address}
                isModalActive={confirmModal.isModalActive}
                confirmAddress={() => setAddressConfirmed(true)}
                toggleModal={confirmModal.toggleModal}
                handleSubmit={submitForm}
                isLoading={isLoading}
              />
              <AddressValidationModal
                address={values?.business_address}
                validatedAddress={validatedAddress?.address}
                isModalActive={validationModal.isModalActive}
                confirmAddress={() => setAddressConfirmed(true)}
                copyAddressToModel={copyAddressToModel}
                toggleModal={validationModal.toggleModal}
                handleSubmit={submitForm}
                isLoading={isLoading}
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
