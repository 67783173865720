import React from 'react';

import { ModalWrapper } from '.';
import OutlineButton from '../buttons/OutlineButton';
import PrimaryButton from '../buttons/PrimaryButton';

export default function AddressUnconfirmedModal({
  address,
  confirmAddress,
  handleSubmit,
  isLoading,
  isModalActive,
  toggleModal,
}) {
  if (!address) return null;
  return (
    <ModalWrapper isModalActive={isModalActive} toggleModal={toggleModal}>
      <div className="px-2 sm:p-3" role={AddressUnconfirmedModal.role}>
        <header className="text-lg sm:text-2xl font-semibold text-primary-blue mb-4">
          Unable to Confirm Address
        </header>
        <section>
          <div className="flex flex-col gap-4 mb-2 modal:mb-0">
            <p className="font-light text-sm sm:text-base">
              We were not able to confirm: <span className="font-semibold">
                {address.unconfirmedComponents.join(', ')}
              </span>
            </p>
            <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-6 text-sm">
              <div className="flex flex-col gap-1 sm:gap-2 sm:w-1/2">
                <p className="font-semibold text-secondary-gray">
                  Address
                </p>
                <div>
                  <p>
                    {address.address1} {address.address2}
                    <span className="inline sm:block">
                      {address.city}, {address.state}{' '}
                      {address.zip}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:flex sm:justify-between sm:gap-4">
              <OutlineButton
                buttonText="Update Address"
                buttonColor="border-primary-blue text-primary-blue hover:text-primary-blue-hover hover:border-primary-blue-hover"
                isSmall={true}
                onClick={() => {
                  toggleModal();
                }}
              />
            </div>
            <p className="font-light text-secondary-gray text-xs sm:text-sm tracking-wide">
              *Note that inaccurate information may result in a declined
              application if we are unable to verify your address.
            </p>
            <p className="text-sm sm:text-base font-light">
              I understand confirming will submit my application with the above
              address
            </p>
          </div>
          <PrimaryButton
            buttonText="Confirm & Continue"
            buttonColor="bg-primary-blue"
            hoverClass="hover:bg-primary-blue-hover"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => {
              confirmAddress();
              handleSubmit();
              toggleModal();
            }}
          />
        </section>
      </div>
    </ModalWrapper>
  );
}

AddressUnconfirmedModal.role = 'address-unconfirmed-modal-component';


