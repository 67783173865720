import axios from 'axios';
import { googleAddressValidationApiKey } from '../constants/SiteVariables';

const validateAddressUrl = 'https://addressvalidation.googleapis.com/v1:validateAddress';

export const addressFromPlace = place => {
  const components = place.address_components;
  const streetNumber = components.find(c => c.types.includes('street_number'));
  const route = components.find(c => c.types.includes('route'));
  const address1 = `${streetNumber?.long_name ? streetNumber.long_name + ' ' : ''}${route?.short_name || ''}`;
  const address2 = components.find(c => c.types.includes('subpremise'));
  const city = components.find(c => c.types.includes('locality'));
  const state = components.find(c => c.types.includes('administrative_area_level_1'));
  const zip = components.find(c => c.types.includes('postal_code'));

  return {
    address1,
    address2: address2?.long_name || '',
    city: city?.long_name || '',
    state: state?.short_name || '',
    zip: zip?.short_name || '',
  };
};

export const validateAddress = async (address, sessionToken, previousResponseId) => {
  const addressLine2 = [address.city, address.state, address.zip];
  const requestUrl = `${validateAddressUrl}?key=${googleAddressValidationApiKey}`;
  const params = {
    address: {
      regionCode: 'US',
      addressLines: [address.address1, addressLine2.join(' ')],
    },
    sessionToken,
    previousResponseId,
  };

  try {
    const response = await axios.post(requestUrl, params);
    const validated = parseValidatedAddress(response.data.result.address, address);
    return { address: validated, response: response.data };
  } catch (e) {
    return {
      address: {
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        zip: address.zip,
        unconfirmedComponents: [],
        diff: [],
      },
      response: {
        message: 'Validation request failed',
        e,
      },
    };
  }
};

const COMPONENT_MAP = {
  street_number: 'Street Number',
  route: 'Street',
  locality: 'City',
  administrative_area_level_1: 'State',
  postal_code: 'Zip Code',
};

const parseValidatedAddress = (validatedAddress, address) => {
  const components = validatedAddress.addressComponents;
  const address1 = validatedAddress.postalAddress.addressLines[0];
  const city = components.find(c => c.componentType === 'locality');
  const state = components.find(c => c.componentType === 'administrative_area_level_1');
  const zip = components.find(c => c.componentType === 'postal_code');
  const diff = [];

  if (address1 !== address.address1) diff.push('address1');
  if (city.componentName.text !== address.city) diff.push('city');
  if (state.componentName.text !== address.state) diff.push('state');
  if (zip.componentName.text !== address.zip) diff.push('zip');

  return {
    address1,
    address2: address.address2,
    city: city.componentName.text,
    state: state.componentName.text,
    zip: zip.componentName.text,
    diff,
    unconfirmedComponents: (validatedAddress.unconfirmedComponentTypes || [])
    .map(ct => COMPONENT_MAP[ct]),
  };
};
